import React, { useState } from 'react';
import { auth, firestore } from '../firebaseConfig'; // Import Firestore
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore'; // Import necessary Firestore functions
import './Register.css'; // Import a separate CSS file for styling

const Register = () => {
  const [password, setPassword] = useState('');
  const [name, setName] = useState(''); // State for name
  const [phoneNumber, setPhoneNumber] = useState(''); // State for phone number
  const [registrationCode, setRegistrationCode] = useState('');
  const [error, setError] = useState(null);

  const handleRegister = async (e) => {
    e.preventDefault();
    setError(null); // Clear any previous errors

    const validRegistrationCode = "QKADMIN"; // Example code, replace with your own logic

    if (registrationCode !== validRegistrationCode) {
      setError('Invalid registration code.');
      return;
    }

    // Validate phone number format (simple check for 10 digits)
    if (!/^\d{10}$/.test(phoneNumber)) {
      setError('Phone number must be exactly 10 digits.');
      return;
    }

    // Construct an email-like format using the phone number
    const email = `${phoneNumber}@quickk.co.in`;

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Add the user data to Firestore
      await setDoc(doc(firestore, 'adminusers', user.uid), {
        name: name,
        phoneNumber: phoneNumber,
        email: email,
        role: 'admin'
      });

      // User registered successfully
      console.log('User registered with admin role');
    } catch (err) {
      setError('Failed to create an account: ' + err.message);
    }
  };

  return (
    <div className="register-container">
      <div className="register-box">
        <h1>Register</h1>
        <form onSubmit={handleRegister}>
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="register-input"
          />
          <input
            type="text"
            placeholder="Phone Number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
            className="register-input"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="register-input"
          />
          <input
            type="text"
            placeholder="Registration Code"
            value={registrationCode}
            onChange={(e) => setRegistrationCode(e.target.value)}
            required
            className="register-input"
          />
          <button type="submit" className="register-button">Register</button>
        </form>
        {error && <p className="error-message">{error}</p>}
      </div>
    </div>
  );
};

export default Register;
