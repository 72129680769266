import React from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../firebaseConfig';
import './Sidebar.css'; // Make sure you have the CSS file in place

const Sidebar = () => {
  const handleLogout = () => {
    auth.signOut().then(() => {
      console.log("User signed out");
    }).catch((error) => {
      console.error("Error signing out: ", error);
    });
  };

  return (
    <div className="sidebar">
      <nav>
        <ul>
          <li><Link to="/">Dashboard</Link></li>
          <li><Link to="/approvals">Approvals</Link></li>
          <li><Link to="/reports">Reports</Link></li>
          <li><Link to="/network">Network</Link></li>
          <li><Link to="/profile">Profile</Link></li>
        </ul>
      </nav>
      <div className="logout-button">
        <button onClick={handleLogout}>Logout</button>
      </div>
    </div>
  );
};

export default Sidebar;
