import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import Dashboard from './pages/Dashboard';
import Approvals from './pages/Approvals';
import Reports from './pages/Reports';
import Network from './pages/Network';
import Profile from './pages/Profile';
import Login from './pages/Login';
import Register from './pages/Register'; // Make sure this path is correct
import { auth } from './firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import './App.css'; // Ensure you have your main CSS file

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      {user ? (
        <div className="flex">
          <Sidebar />
          <div className="app-container flex-grow">
            <Navbar />
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/approvals" element={<Approvals />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="/network" element={<Network />} />
              <Route path="/profile" element={<Profile />} />
            </Routes>
          </div>
        </div>
      ) : (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="*" element={<Login />} /> {/* Redirect unknown routes to login */}
        </Routes>
      )}
    </Router>
  );
}

export default App;
