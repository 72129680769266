import { useState, useEffect } from 'react';
import { firestore } from '../firebaseConfig'; // Adjust the path if necessary
import { collection, query, where, onSnapshot, doc, updateDoc } from 'firebase/firestore';

const Approvals = () => {
  const [approvals, setApprovals] = useState([]);
  const [currentCollection, setCurrentCollection] = useState('users'); // Default to 'users'

  useEffect(() => {
    const fetchApprovals = (collectionName) => {
      const collectionRef = collection(firestore, collectionName);
      const q = query(collectionRef, where('kyc', '==', 'pending'));

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const approvalsList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        console.log(`Fetched Approvals from ${collectionName}:`, approvalsList); // Log fetched data
        setApprovals(approvalsList);
      });

      return unsubscribe;
    };

    console.log(`Listening to changes in ${currentCollection} collection`); // Log current collection being listened to
    const unsubscribe = fetchApprovals(currentCollection);

    return () => {
      console.log(`Unsubscribed from ${currentCollection} collection`); // Log unsubscribe action
      unsubscribe();
    };
  }, [currentCollection]);

  const handleApproval = async (id, status) => {
    const collectionName = currentCollection === 'users' ? 'users' : 'wdUsers';
    const userDoc = doc(firestore, collectionName, id);
    console.log(`Updating ${collectionName} document with ID ${id} to status ${status}`); // Log before updating
    await updateDoc(userDoc, { kyc: status });
    console.log(`Document with ID ${id} updated to status ${status}`); // Log after update
  };

  return (
    <div>
      <h1>KYC Approvals</h1>
      <div>
        <button onClick={() => setCurrentCollection('users')}>Salesman Users</button>
        <button onClick={() => setCurrentCollection('wdUsers')}>Distributor Users</button>
      </div>
      <ul>
        {approvals.length > 0 ? approvals.map(item => (
          <li key={item.id}>
            <p>ID: {item.id}</p>
            <p>Details: {JSON.stringify(item)}</p>
            <button onClick={() => handleApproval(item.id, 'approved')}>Approve</button>
            <button onClick={() => handleApproval(item.id, 'rejected')}>Reject</button>
          </li>
        )) : <p>No pending approvals found</p>}
      </ul>
    </div>
  );
};

export default Approvals;
