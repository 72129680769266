import React from 'react';
import './Navbar.css'; // Ensure you have the CSS file in place

const Navbar = () => {
  return (
    <div className="navbar">
      <h1>Welcome to Admin Panel</h1>
    </div>
  );
};

export default Navbar;
