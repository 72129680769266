import React, { useEffect, useState } from 'react';
import { auth, firestore } from '../firebaseConfig'; // Import the Firestore instance
import { doc, getDoc } from 'firebase/firestore'; // Import necessary Firestore functions
import './Profile.css'; // Import CSS for styling

const Profile = () => {
  const [profile, setProfile] = useState(null); // State to store profile data
  const [loading, setLoading] = useState(true); // State to manage loading state
  const [error, setError] = useState(null); // State to handle errors

  useEffect(() => {
    const fetchProfile = async () => {
      setLoading(true);
      setError(null);

      try {
        const user = auth.currentUser; // Get the currently logged-in user
        if (user) {
          const profileDocRef = doc(firestore, 'adminusers', user.uid); // Reference to the user's profile document
          const profileDoc = await getDoc(profileDocRef); // Fetch the profile document

          if (profileDoc.exists()) {
            setProfile(profileDoc.data()); // Set the profile data in state
          } else {
            setError('Profile not found.');
          }
        } else {
          setError('No user is currently logged in.');
        }
      } catch (err) {
        setError('Failed to fetch profile: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  if (loading) {
    return <p>Loading profile...</p>;
  }

  if (error) {
    return <p className="error-message">{error}</p>;
  }

  return (
    <div className="profile-container">
      <div className="profile-box">
        <h1>Profile</h1>
        {profile ? (
          <div className="profile-details">
            <p><strong>Name:</strong> {profile.name}</p>
            <p><strong>Phone Number:</strong> {profile.phoneNumber}</p>
            <p><strong>Role:</strong> {profile.role}</p>
          </div>
        ) : (
          <p>No profile information available.</p>
        )}
      </div>
    </div>
  );
};

export default Profile;
