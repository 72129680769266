// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber, PhoneAuthProvider } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyBBpePoLWYYaBc0xWHo016lGwDs4S_N_ac",
    authDomain: "ds-panel-quickk.firebaseapp.com",
    projectId: "ds-panel-quickk",
    storageBucket: "ds-panel-quickk.appspot.com",
    messagingSenderId: "665703047524",
    appId: "1:665703047524:web:d94feef2e0f51aa16fda71"
  };

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const auth = getAuth(app);

if (process.env.NODE_ENV === 'test') {
    auth.settings.appVerificationDisabledForTesting = true;
  }
  

export { firestore, auth, RecaptchaVerifier, signInWithPhoneNumber, PhoneAuthProvider };
