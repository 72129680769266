import React from 'react';
import './Dashboard.css';

const Dashboard = () => {
  return (
    <div>
      <h1>Dashboard</h1>
      <p>Welcome to the admin dashboard.</p>
      
      <div className="dashboard-grid">
        <div className="dashboard-item">
          <h2>Sales</h2>
          <p>Data related to Sales will be displayed here.</p>
        </div>
        
        <div className="dashboard-item">
          <h2>Bill Cut</h2>
          <p>Data related to Bill Cut will be displayed here.</p>
        </div>
        
        <div className="dashboard-item">
          <h2>Active Outlets</h2>
          <p>Data related to Active Outlets will be displayed here.</p>
        </div>
        
        <div className="dashboard-item">
          <h2>Active DS</h2>
          <p>Data related to Active DS will be displayed here.</p>
        </div>
        
        <div className="dashboard-item">
          <h2>Active WD</h2>
          <p>Data related to Active WD will be displayed here.</p>
        </div>
        
        <div className="dashboard-item">
          <h2>New Onboarding</h2>
          <p>Data related to New Onboarding will be displayed here.</p>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
