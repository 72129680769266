import React, { useEffect, useState } from 'react';
import { firestore } from '../firebaseConfig'; // Adjust the path if needed
import { collection, getDocs } from 'firebase/firestore'; // Import necessary Firestore methods
import './Network.css'; // Import a separate CSS file for styling

const Network = () => {
  const [wdUsers, setWdUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchWdUsers = async () => {
      try {
        // Fetch wdUsers from Firestore
        const wdUsersSnapshot = await getDocs(collection(firestore, 'wdUsers'));
        const wdUsersData = wdUsersSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setWdUsers(wdUsersData);
      } catch (err) {
        setError('Failed to fetch wdUsers: ' + err.message);
      }
    };

    const fetchUsers = async () => {
      try {
        // Fetch users from Firestore
        const usersSnapshot = await getDocs(collection(firestore, 'users'));
        const usersData = usersSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUsers(usersData);
      } catch (err) {
        setError('Failed to fetch users: ' + err.message);
      }
    };

    fetchWdUsers();
    fetchUsers();
  }, []);

  // Function to export data to CSV with sections
  const exportToCSV = () => {
    const csvRows = [];
    const headers = ['Name', 'ID'];

    // Add Distributors header
    csvRows.push(['Distributors']);
    csvRows.push(headers.join(','));

    // Add wdUsers data
    wdUsers.forEach(wdUser => {
      const row = [wdUser.name, wdUser.wdId];
      csvRows.push(row.join(','));
    });

    // Add a blank line to separate sections
    csvRows.push([]);

    // Add Salesman header
    csvRows.push(['Salesman']);
    csvRows.push(headers.join(','));

    // Add users data
    users.forEach(user => {
      const row = [user.name, user.dsId];
      csvRows.push(row.join(','));
    });

    // Convert rows to CSV format
    const csvContent = csvRows.join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'network_data.csv';
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <div className="network-container">
      <h1>Network</h1>
      {error && <p className="error-message">{error}</p>}
      
      {/* Export button */}
      <div className="export-button-container">
        <button className="export-button" onClick={exportToCSV}>Export to CSV</button>
      </div>
      
      <div className="network-box-container">
        <div className="network-box">
          <h2>Distributors</h2>
          <div className="list-box">
            <div className="list-header">
              <span>Name</span>
              <span>ID</span>
            </div>
            {wdUsers.map((wdUser) => (
              <div className="list-item" key={wdUser.id}>
                <span>{wdUser.name}</span>
                <span>{wdUser.wdId}</span>
              </div>
            ))}
          </div>
        </div>
        
        <div className="network-box">
          <h2>Salesman</h2>
          <div className="list-box">
            <div className="list-header">
              <span>Name</span>
              <span>ID</span>
            </div>
            {users.map((user) => (
              <div className="list-item" key={user.id}>
                <span>{user.name}</span>
                <span>{user.dsId}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Network;
