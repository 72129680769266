import React, { useState, useEffect } from 'react';
import './Reports.css';
import { CSVLink } from 'react-csv';
import { firestore } from '../firebaseConfig';
import { collection, getDocs, query, where } from 'firebase/firestore';

const Reports = () => {
  const [activeTab, setActiveTab] = useState('dsReports');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [checkinData, setCheckinData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleTabClick = (tab) => setActiveTab(tab);

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === 'startDate') setStartDate(value);
    if (name === 'endDate') setEndDate(value);
  };

  const fetchCheckinCheckoutData = async () => {
    if (!startDate || !endDate) {
      alert('Please select both start and end dates');
      return;
    }
    setLoading(true);
    try {
      const checkinsCollection = collection(firestore, 'checkins');
      const q = query(
        checkinsCollection,
        where('checkinTimestamp', '>=', new Date(startDate)),
        where('checkinTimestamp', '<=', new Date(endDate))
      );
      const checkinsSnapshot = await getDocs(q);
      const checkins = checkinsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCheckinData(checkins);
    } catch (error) {
      console.error('Error fetching checkin data:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (startDate && endDate) {
      fetchCheckinCheckoutData();
    }
  }, [startDate, endDate]);

  const formatTimestamp = (timestamp) => {
    return timestamp ? new Date(timestamp.seconds * 1000).toLocaleString() : 'N/A';
  };

  const formatTimestampForCSV = (timestamp) => {
    return timestamp ? new Date(timestamp.seconds * 1000).toISOString() : 'N/A';
  };

  const csvData = checkinData.map((data) => ({
    dsID: data.dsID,
    checkinTimestamp: formatTimestampForCSV(data.checkinTimestamp),
    checkinoutTimestamp: formatTimestampForCSV(data.checkinoutTimestamp),
  }));

  const csvHeaders = [
    { label: 'DS ID', key: 'dsID' },
    { label: 'Checkin Timestamp', key: 'checkinTimestamp' },
    { label: 'Checkout Timestamp', key: 'checkinoutTimestamp' },
  ];

  return (
    <div className="reports-container">
      <h1>Reports</h1>
      <div className="tabs">
        <button
          className={activeTab === 'dsReports' ? 'active' : ''}
          onClick={() => handleTabClick('dsReports')}
        >
          DS Reports
        </button>
        <button
          className={activeTab === 'wdReports' ? 'active' : ''}
          onClick={() => handleTabClick('wdReports')}
        >
          WD Reports
        </button>
      </div>

      <div className="date-filters">
        <label>
          Start Date:
          <input
            type="date"
            name="startDate"
            value={startDate}
            onChange={handleDateChange}
          />
        </label>
        <label>
          End Date:
          <input
            type="date"
            name="endDate"
            value={endDate}
            onChange={handleDateChange}
          />
        </label>
      </div>

      {activeTab === 'dsReports' && (
        <div className="reports-content">
          <h2>DS Reports</h2>

          <div className="report-box">
            <div className="report-header">
              <h3>CheckinCheckout Report</h3>
              {checkinData.length > 0 && (
                <CSVLink
                  data={csvData}
                  headers={csvHeaders}
                  filename={`checkin_checkout_report_${startDate}_${endDate}.csv`}
                  className="export-button"
                >
                  Export CSV
                </CSVLink>
              )}
            </div>

            {loading ? (
              <p>Loading data...</p>
            ) : (
              <>
                {checkinData.length > 0 ? (
                  <table className="report-table">
                    <thead>
                      <tr>
                        <th>DS ID</th>
                        <th>Checkin Timestamp</th>
                        <th>Checkout Timestamp</th>
                      </tr>
                    </thead>
                    <tbody>
                      {checkinData.map((checkin) => (
                        <tr key={checkin.id}>
                          <td>{checkin.dsID}</td>
                          <td>{formatTimestamp(checkin.checkinTimestamp)}</td>
                          <td>{formatTimestamp(checkin.checkinoutTimestamp)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p>No data available for the selected date range.</p>
                )}
              </>
            )}
          </div>

          <div className="report-box">
            <h3>Bill Cut Report</h3>
            {/* Add functionality to generate this report */}
          </div>
        </div>
      )}

      {activeTab === 'wdReports' && (
        <div className="reports-content">
          <h2>WD Reports</h2>

          <div className="report-box">
            <h3>Beat Wise Sales</h3>
            {/* Add functionality to generate this report */}
          </div>

          <div className="report-box">
            <h3>DS Wise Sales</h3>
            {/* Add functionality to generate this report */}
          </div>
        </div>
      )}
    </div>
  );
};

export default Reports;
